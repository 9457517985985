
import { FetchResult } from '@apollo/client';
import { MovePhoneInput, PersonType, SearchDuplicatePhonesQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { PersonViewModel } from './shared/PersonViewModel';

export type Person = Extract<
  SearchDuplicatePhonesQuery['searchDuplicatePhones'],
  { personID: number }
>;
export type PersonEmailAddress = ArrayElement<Person['EmailAddresses']>;
export type Phone = ArrayElement<Person['Phones']>;
export type Child = ArrayElement<Person['Children']>;
export type Region = NonNullable<Person['Teen']>['Region'];

export enum ErrorEnum {
  DuplicateFound = 'Error Duplicate Found',
  PhoneValidationFaild = 'The phone is invalid',
  RequiredField = 'Phone is a required field'
}
export const RegionsMap = {
  1: 'Northeast',
  2: 'Atlantic Seaboard',
  3: 'Central East',
  4: 'West Coast',
  7: 'New York',
  8: 'Canada',
  9: 'Southwest',
  10: 'Southern',
  11: 'Midwest',
  13: 'Upstate New York',
  14: 'New Jersey',
  28: 'Atlanta',
  29: 'Argentina',
  30: 'Israel',
  31: 'International',
  32: 'Chile',
  33: 'National',
  35: 'Alumni'
};

export type EmailAddress = PersonEmailAddress[] | string | null;
export interface Props {
  owner: PersonViewModel;
  ownerId: number,
  ownerType: PersonType,
  value?: string,
  required: boolean;
  width?: string;
  left: boolean;
  narrow: boolean;
  placeholder?: string;
  showErrors: boolean;
  validateOnBlur: boolean;
  name?: string;
  autocomplete?: string;
  checkIsDuplicate?: boolean;
  duplicate: PersonViewModel;
  searching: boolean;
  movingPhone: boolean;
  externalErrors?: Record<string, string>;
  searchPhoneDuplicates: (phone: string) => Promise<FetchResult<SearchDuplicatePhonesQuery>>;
  setParentId?: (parentId: number) => void;
  setCommunityMemberId?: (communityMemberId: number) => void;
  setTeenId?: (teenId: number) => void;
  movePhone: (args: MovePhoneInput) => Promise<void>;
  deletePhone: (id: number) => Promise<void>;
  closeSidePanel?: () => Promise<void>;
}
