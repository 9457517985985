var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('nav',[_c('span',{staticClass:"nav-item",class:{ active: _setup.selectedComponent === 'Summary' },on:{"click":function($event){_setup.selectedComponent = `Summary`}}},[_vm._v("Summary")]),_c('span',{staticClass:"nav-item",class:{ active: _setup.selectedComponent === 'EmailHistory' },on:{"click":function($event){_setup.selectedComponent = `EmailHistory`}}},[_vm._v(" Email History ")])]),_c('div',{staticClass:"main-panel"},[_c(_setup.ScrollablePanel,[_c(_setup.selectedComponentComponent,_vm._b({key:_setup.selectedComponent,tag:"component",staticClass:"parent-panel",attrs:{"setDuplicateParentId":_vm.setDuplicateParentId,"setTeenId":_vm.setTeenId,"setCommunityMemberId":_vm.setCommunityMemberId,"closeSidePanel":_vm.closeSidePanel}},'component',{
          family: _vm.family,
          regionId: _vm.regionId,
          familyId: +_vm.familyId,
          setParentId: _vm.setParentId,
          parent: _vm.parent,
          associateHandler: _vm.associateHandler,
        },false))],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }