import { render, staticRenderFns } from "./RecordAttendees.vue?vue&type=template&id=76efb3bb&scoped=true&"
import script from "./RecordAttendees.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RecordAttendees.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RecordAttendees.vue?vue&type=style&index=0&id=76efb3bb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76efb3bb",
  null
  
)

export default component.exports