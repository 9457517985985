
import {
  dateFormat,
  getCurrentEmail,
  getCurrentAddress,
  getCurrentCellNumber,
  formatAddressToString,
  getCurrentLandlineNumber,
  phoneFormat
} from 'shared/util';
import trim from 'lodash/trim';
import startCase from 'lodash/startCase';
import flatten from 'lodash/flatten';
import { Person } from './types';
import {
  FamilyParentFragmentFragment,
  PersonType,
  PhoneType
} from 'shared/generated/graphql-types';
import { PersonViewModel } from './shared/PersonViewModel';
import { getGender } from '../EmailInput/utils';

export function toPersonViewModel(person: Person | undefined | null): PersonViewModel | null {
  if (!person || !person.personID) return null;

  const currentAddress = getCurrentAddress(person.Addresses || []);
  const formattedAddress = formatAddressToString(currentAddress);

  return {
    type: person.type,
    id: person.personID,
    gender: getGender(person.gender)!,
    isParent: person.type === PersonType.Parent,
    isTeen: person.type === PersonType.Teen,
    firstName: startCase(person.firstName || ''),
    lastName: startCase(person.lastName || ''),
    fullName: trim(`${startCase(person.firstName || '')} ${startCase(person.lastName || '')}`),
    birthDate: dateFormat(person.Teen && person.Teen.birthDate),
    address: currentAddress,
    landline: phoneFormat(
      getCurrentLandlineNumber(person.Phones || [])
        ? getCurrentLandlineNumber(person.Phones)!.phoneNumber
        : null
    ),
    cellPhones: person.Phones.filter(p => p.type === PhoneType.Mobile) || [],
    currentCellPhone: 
      getCurrentCellNumber(person.Phones || [])
        ? getCurrentCellNumber(person.Phones)!.phoneNumber
        : null,
    currentCellPhoneId: getCurrentCellNumber(person.Phones || []) ? getCurrentCellNumber(person.Phones)!.id : 0,
    email: (getCurrentEmail(person.EmailAddresses) || { email: '' }).email,
    emailId: (getCurrentEmail(person.EmailAddresses) || { id: null }).id || null,
    emails: person.EmailAddresses || [],
    thumbnail: (person.Teen && person.Teen.thumbnail) || null,
    formattedAddress,
    region: (person.Teen && person.Teen.Region) || null,
    regionId: (person.Teen && person.Teen.regionId) || null,
    parents: flatten((person.ChildOf || []).map((c) => [c.Father, c.Mother]))
      .filter((c): c is FamilyParentFragmentFragment => c !== null)
      .map((c) => ({ personID: c.personID })),
    children: person.Children,
    primaryFamilyId: (person.PrimaryFamily && person.PrimaryFamily.id) || null,
    primaryFamily: person.PrimaryFamily
  };
}
