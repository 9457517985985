import PhoneInput from './PhoneInput.vue';
import { computed } from 'vue';
import { compose, withProps } from 'vue-compose';
import { FetchResult } from '@apollo/client';
import { Person, Props } from './types';
import { toPersonViewModel } from './utils';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import {
  GetPersonDocument,
  GetPersonQuery,
  GetPersonQueryVariables,
  MovePhoneInput,
  SearchDuplicatePhonesQuery,
  useDeletePhoneMutation,
  useGetPersonQuery,
  useMovePhoneMutation,
  useSearchDuplicatePhonesQuery
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { PersonViewModel } from './shared/PersonViewModel';

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

interface SearchDuplicatePhonesProps {
  duplicate: PersonViewModel | null;
  searching: boolean;
  searchPhoneDuplicates: (
    phone: string
  ) => Promise<FetchResult<SearchDuplicatePhonesQuery>> | undefined;
}
const searchDuplicatePhonesEnhancer = wrapComponent<Props, SearchDuplicatePhonesProps>((props) => {
  const { loading, result, refetch } = useSearchDuplicatePhonesQuery(
    computed(() => ({
      phone: ''
    }))
  );

  return computed(() => ({
    duplicate: toPersonViewModel(result.value?.searchDuplicatePhones as Person),
    searchPhoneDuplicates: (cellPhone) => refetch({ phone: cellPhone }),
    searching: loading.value
  }));
});

interface GetPersonViewModelProps {
  owner: PersonViewModel | null;
}

const getPersonByIdEnhancer = wrapComponent<Props, GetPersonViewModelProps>((props) => {
  const { result } = useGetPersonQuery(
    computed(() => ({
      personID: props.ownerId
    })),
    { enabled: computed(() => !!props.ownerId && !!props.ownerType) }
  );

  return computed(() => ({
    owner: toPersonViewModel(result.value?.person as Person)
  }));
});

interface MovePhoneProp {
  movePhone: (args: MovePhoneInput, currentPersonId: number) => Promise<void>;
  movingPhone: boolean;
}

const movePhoneEnhancer = wrapComponent<Props, MovePhoneProp>(() => {
  const { loading, mutate } = useMovePhoneMutation();

  return computed(() => ({
    movingPhone: loading.value,
    movePhone: async (args: MovePhoneInput, currentPersonId: number) => {
      await mutate(
        { args },
        {
          update: (cache, { data }) => {
            if (data?.movePhoneToCurrentAccount) {
              const currentPerson = cache.readQuery<GetPersonQuery, GetPersonQueryVariables>({
                query: GetPersonDocument,
                variables: { personID: currentPersonId }
              });

              if (currentPerson) {
                const phone = currentPerson.person.Phones.find((p) => p.id === args.phoneNumberId);

                cache.writeQuery<GetPersonQuery, GetPersonQueryVariables>({
                  query: GetPersonDocument,
                  data: {
                    person: {
                      ...currentPerson.person,
                      Phones: currentPerson.person.Phones.filter((p) => p.id !== args.phoneNumberId)
                    }
                  }
                });

                const targetPerson = cache.readQuery<GetPersonQuery, GetPersonQueryVariables>({
                  query: GetPersonDocument,
                  variables: { personID: args.targetPersonId }
                });

                if (targetPerson && phone) {
                  cache.writeQuery<GetPersonQuery, GetPersonQueryVariables>({
                    query: GetPersonDocument,
                    data: {
                      person: {
                        ...targetPerson.person,
                        Phones: [...targetPerson.person.Phones, phone]
                      }
                    }
                  });
                }
              }
            }
          }
        }
      );
    }
  }));
});

interface DeletePhoneProp {
  deletingPhone: boolean;
  deletePhone: (id: number, duplicatePersonId: number) => Promise<void>;
}

export const deletePhoneEnhancer = wrapComponent<Props & { personId?: number }, DeletePhoneProp>(
  () => {
    const { loading, mutate } = useDeletePhoneMutation();

    return computed(() => ({
      deletingPhone: loading.value,
      deletePhone: async (id: number, duplicatePersonId: number) => {
        await mutate(
          { id },
          {
            update: (cache) => {
              const person = cache.readQuery<GetPersonQuery, GetPersonQueryVariables>({
                query: GetPersonDocument,
                variables: { personID: duplicatePersonId }
              });

              if (person) {
                cache.writeQuery<GetPersonQuery, GetPersonQueryVariables>({
                  query: GetPersonDocument,
                  data: {
                    person: {
                      ...person.person,
                      Phones: person.person.Phones.filter((p) => p.id !== id)
                    }
                  }
                });
              }
            }
          }
        );
      }
    }));
  }
);

export default compose(
  withProps(() => ({
    regionId: getCurrentRegion()
  })),
  getPersonByIdEnhancer,
  searchDuplicatePhonesEnhancer,
  movePhoneEnhancer,
  deletePhoneEnhancer
)(PhoneInput);
