var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.loadingCommunityMemberProfile && _vm.communityMember)?_c('div',{staticClass:"profile",attrs:{"id":"profile"}},[_c('header',{staticClass:"profile-header"},[(_vm.isOpenForDuplicate)?_c(_setup.UIButton,{staticClass:"return-button",on:{"click":function($event){$event.preventDefault();return _vm.returnToProfile.apply(null, arguments)}}},[_vm._v(" Return ")]):_vm._e(),_c('div',{staticClass:"img-container"},[_c(_setup.Avatar,{attrs:{"size":80,"username":_setup.fullName,"backgroundColor":_setup.getGenderColor(_vm.communityMember.gender)}}),_c(_setup.ProfilePhoto,[_vm._v(" Change ")])],1),_c(_setup.UISwitch,{staticClass:"edit-switch",attrs:{"onText":"Edit","width":"70","offText":"Edit"},model:{value:(_setup.editingMode),callback:function ($$v) {_setup.editingMode=$$v},expression:"editingMode"}}),_c('span',{staticClass:"communityMember-name"},[_vm._v(_vm._s(_setup.fullName))]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(`Change password`),expression:"`Change password`"}],staticClass:"communityMember-password",on:{"click":function($event){return _setup.emit('showPasswordModal', !_vm.showPasswordModal)}}},[_c('font-awesome-icon',{attrs:{"icon":"key","fixed-width":""}}),_c('span',[_c('font-awesome-icon',{attrs:{"icon":"redo","fixed-width":""}})],1)],1),_c(_setup.Modal,{staticClass:"modal--password",attrs:{"show":_vm.showPasswordModal,"requestClose":() => {
            _setup.emit('showPasswordModal', false);
            _setup.password = null;
          }},on:{"input":function($event){_setup.password = $event}},scopedSlots:_vm._u([{key:"default",fn:function({ $listeners }){return [_c('div',{staticClass:"flex"},[_c('h1',[_vm._v("Reset Password")]),_vm._v("     "),(_vm.changingPassword)?_c('span',{staticClass:"spinner"},[_c('span',{staticClass:"icon"})]):_vm._e()]),_c(_setup.UIPassword,_vm._g({},$listeners)),_c('br'),_c(_setup.UIButton,{on:{"click":_setup.passwordHandler}},[_vm._v(_vm._s(_vm.changingPassword ? "Reseting..." : "Reset"))])]}}],null,false,190951065)})],1),_c('main',{class:{ editmode: _setup.editingMode }},[_c(_setup.ScrollablePanel,{directives:[{name:"fullheight",rawName:"v-fullheight",value:({ bottom: 0 }),expression:"{ bottom: 0 }"}]},[_c('section',[_c(_setup.FilterGroup,{attrs:{"header":"Contact Information"}},[_c('ul',{staticClass:"content-list"},[_c('li',[_c('span',{staticClass:"icn"},[_c('font-awesome-icon',{attrs:{"icon":"envelope","fixed-width":""}})],1),(_setup.editingMode)?_c('span',[_c(_setup.AutoSaveField,{attrs:{"name":"email","entity":_setup.communityMemberCopy,"transform":(c) => ({
                        value: c,
                        fieldName: _setup.CommunityMemberFieldsEnum.email,
                      })},on:{"update:entity":function($event){_setup.communityMemberCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.EmailInput,_vm._g(_vm._b({attrs:{"required":true,"ownerType":"communityMember","narrow":true,"placeholder":"Email","ownerId":_vm.personID,"showErrors":true}},'EmailInput',$props,false),$listeners))]}}],null,false,4022211846)})],1):_c('span',{on:{"click":function($event){return _setup.copyToClipboard(_vm.communityMember.email)}}},[(_vm.communityMember.email)?_c('a',{attrs:{"target":"_blank","href":`mailto:${_vm.communityMember.email}`}},[_vm._v(_vm._s(_vm.communityMember.email))]):_c('span',{staticClass:"missing-info"},[_vm._v("Email missing")])])]),_c('li',[_c('span',{staticClass:"icn"},[_c('font-awesome-icon',{attrs:{"icon":"mobile-alt","fixed-width":""}})],1),(_setup.editingMode)?_c('span',[(_setup.communityMemberCopy)?_c(_setup.AutoSaveField,{attrs:{"name":"formattedCellphone","entity":_setup.communityMemberCopy,"toForm":(c) => (_vm.validateNumber(c) ? _setup.phoneFormat(c) : ''),"toEntity":_vm.phoneInternational,"transform":(c) => ({
                        value: c,
                        fieldName: _setup.CommunityMemberFieldsEnum.cellphone,
                      }),"validators":[
                      {
                        name: 'validateNumber',
                        validator: (value) => _vm.validateNumber(value, true),
                      },
                    ]},on:{"update:entity":function($event){_setup.communityMemberCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.PhoneInput,_vm._g(_vm._b({attrs:{"type":"tel","name":"cellphone","check-is-duplicate":true,"ownerType":_setup.PersonType.CommunityMember,"narrow":true,"placeholder":"Cell phone number","ownerId":_vm.personID,"showErrors":true,"left":false}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Cellphone Number must be a number")]):_vm._e()]}}],null,false,2628710276)}):_vm._e()],1):_c('span',{on:{"click":function($event){return _setup.copyToClipboard(_vm.communityMember.formattedCellphone)}}},[(_vm.communityMember.cellphone)?_c('span',[_vm._v(_vm._s(_setup.phoneFormat(_vm.communityMember.formattedCellphone)))]):_c('span',{staticClass:"missing-info"},[_vm._v("Cellphone missing")])])]),_c('li',{staticClass:"communityMember"},[_c('span',{staticClass:"icn"},[_c('font-awesome-icon',{attrs:{"icon":"phone","fixed-width":""}})],1),(_setup.editingMode)?_c('span',[(_setup.communityMemberCopy)?_c(_setup.AutoSaveField,{attrs:{"name":"formattedLandline","entity":_setup.communityMemberCopy,"toForm":(c) => (_vm.validateNumber(c) ? _setup.phoneFormat(c) : ''),"toEntity":_vm.phoneInternational,"transform":(c) => ({
                        value: c,
                        fieldName: _setup.CommunityMemberFieldsEnum.landline,
                      }),"validators":[
                      {
                        name: 'validateNumber',
                        validator: (value) => _vm.validateNumber(value, true),
                      },
                    ]},on:{"update:entity":function($event){_setup.communityMemberCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.PhoneInput,_vm._g(_vm._b({attrs:{"type":"tel","name":"landline","check-is-duplicate":true,"ownerType":_setup.PersonType.CommunityMember,"narrow":true,"placeholder":"Lanline phone number","ownerId":_vm.personID,"showErrors":true,"left":false}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Home Phone Number must be a number")]):_vm._e()]}}],null,false,2739939093)}):_vm._e()],1):_c('span',{on:{"click":function($event){return _setup.copyToClipboard(_vm.communityMember.formattedLandline)}}},[(_vm.communityMember.landline)?_c('span',[_vm._v(_vm._s(_setup.phoneFormat(_vm.communityMember.formattedLandline)))]):_c('span',{staticClass:"missing-info"},[_vm._v("Landline missing")])])])])])],1),_c('section',[_c(_setup.FilterGroup,{attrs:{"header":"Personal Information"}},[_c('ul',{staticClass:"content-list"},[_c('li',{staticClass:"communityMember"},[_c('span',{staticClass:"icn"},[_c('font-awesome-icon',{attrs:{"icon":"home","fixed-width":""}})],1),(_setup.editingMode)?_c('div',[(_setup.communityMemberCopy)?_c(_setup.AutoSaveField,{attrs:{"name":"address","entity":_setup.communityMemberCopy,"transform":(c) => ({
                        value: JSON.stringify(c),
                        fieldName: _setup.CommunityMemberFieldsEnum.address,
                      })},on:{"update:entity":function($event){_setup.communityMemberCopy=$event},"save":_setup.update},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.Address,_vm._g(_vm._b({key:JSON.stringify(_setup.communityMemberCopy.address || {}),scopedSlots:_vm._u([{key:"default",fn:function({
                            countries,
                            setCountry,
                            setAddress,
                            country,
                            address,
                            lookupAddress,
                          }){return [_c('div',{staticClass:"form-row smaller"},[_c('select',{domProps:{"value":country},on:{"change":(e) => {
                                  setCountry(e.currentTarget.value);
                                  lookupAddress();
                                }}},_vm._l((countries),function(country){return _c('option',{key:country.code,domProps:{"value":country.code}},[_vm._v(" "+_vm._s(country.name)+" ")])}),0)]),_c('div',{staticClass:"form-row smaller"},[_c(_setup.UITextarea,{attrs:{"placeholder":"Address","value":address},on:{"blur":lookupAddress,"input":function($event){return setAddress($event)}}})],1)]}}],null,true)},'Address',$props,false),$listeners))]}}],null,false,2453082616)}):_vm._e()],1):_c('div',{on:{"click":function($event){return _setup.copyToClipboard(_vm.communityMember.formattedAddress)}}},[(_vm.communityMember.formattedAddress)?_c('span',[_vm._v(_vm._s(_vm.communityMember.formattedAddress))]):_c('span',{staticClass:"missing-info"},[_vm._v("Address missing")])])])])])],1),_c('section',[_c(_setup.FilterGroup,{attrs:{"header":"Media Consent"}},[_c('ul',{staticClass:"content-list"},[_c('li',{staticClass:"communityMember"},[_c('div',{staticClass:"consent"},[_c('span',{staticClass:"icn"},[_c('font-awesome-icon',{attrs:{"icon":"database","fixed-width":""}})],1),_vm._v(" Data Opt Out: "),_c('strong',[_vm._v("  "+_vm._s(_setup.isNull(_setup.communityMemberCopy.dataOptOut) ? " N/A" : _setup.communityMemberCopy.dataOptOut ? " Yes" : " No")+" ")])])])])])],1)])],1)]):_vm._e(),(_vm.loadingCommunityMemberProfile)?_c(_setup.Loading):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }