import { MutateResult } from '@vue/apollo-composable';
import { EntityUnionViewModel } from 'shared/components/EmailInput/shared/EntityUnionViewModel';
import {
  ConnectTeenToParentMutation,
  ConnectTeenToParentMutationVariables,
  ConsentDataInput,
  CreateAddress,
  CreateAddressMutation,
  CreateEmail,
  CreateEmailMutation,
  CreatePhone,
  CreatePhoneMutation,
  FamilyWithChildrenFragment,
  GetParentProfileQuery,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  UpdateAddress,
  UpdateAddressMutation,
  UpdateEmail,
  UpdateEmailMutation,
  UpdatePhone,
  UpdatePhoneMutation
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

export type Parent = GetParentProfileQuery['singleParent'];
export type Address = ArrayElement<Parent['Addresses']>;
export type Child = ArrayElement<Parent['Children']>;
export type EmailAddress = ArrayElement<Parent['EmailAddresses']>;
export type Phone = ArrayElement<Parent['Phones']>;
export type FamilyChild = ArrayElement<FamilyWithChildrenFragment['Children']>;

export interface Props {
  changePassword: (args: ResetPasswordMutationVariables) => MutateResult<ResetPasswordMutation>;
  updateEmail: (
    args: UpdateEmail,
    optimisticResponseData?: ParentUpdateArgs
  ) => MutateResult<UpdateEmailMutation>;
  createEmail: (
    args: CreateEmail,
    optimisticResponseData?: ParentUpdateArgs
  ) => MutateResult<CreateEmailMutation>;
  updatePhone: (
    args: UpdatePhone,
    optimisticResponseData?: ParentUpdateArgs
  ) => MutateResult<UpdatePhoneMutation>;
  createPhone: (
    args: CreatePhone,
    optimisticResponseData?: ParentUpdateArgs
  ) => MutateResult<CreatePhoneMutation>;
  updateAddress: (
    args: UpdateAddress,
    optimisticResponseData?: ParentUpdateArgs
  ) => MutateResult<UpdateAddressMutation>;
  createAddress: (
    args: CreateAddress,
    optimisticResponseData?: ParentUpdateArgs
  ) => MutateResult<CreateAddressMutation>;
  personID: number;
  changingPassword: boolean;
  showPasswordModal: boolean;
  parent: ParentViewModel;
  markParentConsent: (parentId: number, data: ConsentDataInput) => void;
  connectTeenToParent: (
    args: ConnectTeenToParentMutationVariables,
    child: EntityUnionViewModel
  ) => MutateResult<ConnectTeenToParentMutation>;
  isOpenForDuplciate: boolean;
  returnToProfile: () => void;
}
export enum ParentFieldsEnum {
  email = 'email',
  landline = 'landline',
  cellphone = 'cellphone',
  address = 'address',
  firstName = 'firstName',
  lastName = 'lastName',
  fullName = 'fullName',
  gender = 'gender',
  olamiId = 'olamiId'
}
export interface ParentUpdateArgs {
  personId: number;
  fieldName: ParentFieldsEnum;
  value: string | null;
}
export interface ParentViewModel {
  address: Address | null;
  personId: number;
  landline: Phone | null;
  cellphone: Phone | null;
  formattedCellphone: string | null;
  formattedLandline: string | null;
  formattedAddress: string;
  email: string | null;
  emails: EmailAddress[];
  emailId: number | null;
  firstName: string | null;
  lastName: string | null;
  olamiId: string | null;
  fullName: string;
  dataOptOut: boolean | null;
  gender: number;
  children: ViewModelChildren[];
  original: Parent;
}
export interface ViewModelChildren {
  landline: string | null;
  cellphone: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}
