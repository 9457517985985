import Financials from './Financials.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import remove from 'lodash/remove';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  DeletePaymentMutation,
  GetRegistrationDocument,
  GetRegistrationQuery,
  ProcessRefundInput,
  RefundPaymentMutation,
  SelectedRegistrationFragment,
  SelectedRegistrationFragmentDoc,
  useDeletePaymentMutation,
  useRefundPaymentMutation
} from 'shared/generated/graphql-types';
import { MutateResult } from '@vue/apollo-composable';
import { useApolloClient } from '@vue/apollo-composable';

type Registration = GetRegistrationQuery['registration'];

interface Props {
  registration: Registration;
}

interface DeletePaymentProps {
  deletePayment: (paymentId: number) => MutateResult<DeletePaymentMutation>;
  deletingPayment: boolean;
}

const deletePaymentEnhancer = wrapComponent<Props, DeletePaymentProps>((props) => {
  const { loading, mutate } = useDeletePaymentMutation();

  return computed(() => ({
    deletingPayment: loading.value,
    deletePayment: (paymentId) =>
      mutate(
        { paymentId, registrationId: props.registration.registrationID },
        {
          optimisticResponse: {
            deletePayment: paymentId
          },
          update(client) {
            try {
              const registrationData = client.readFragment<SelectedRegistrationFragment>({
                fragment: SelectedRegistrationFragmentDoc,
                id: `${props.registration.registrationID}Registration`,
                fragmentName: 'SelectedRegistration'
              });
              if (registrationData) {
                const lineItems = registrationData.LineItems.slice();
                const { amount } = lineItems.find((lineItem) => lineItem.trackById === paymentId)!;
                const lineItemBalanceIndex = lineItems.findIndex(
                  (lineItem) => lineItem.description === 'Balance'
                );
                lineItems[lineItemBalanceIndex] = {
                  ...lineItems[lineItemBalanceIndex],
                  amount: lineItems[lineItemBalanceIndex].amount + Math.abs(amount)
                };

                client.writeFragment<SelectedRegistrationFragment>({
                  fragment: SelectedRegistrationFragmentDoc,
                  id: `${props.registration.registrationID}Registration`,
                  fragmentName: 'SelectedRegistration',
                  data: {
                    ...registrationData,
                    balance: registrationData.balance + Math.abs(amount),
                    LineItems: remove(lineItems, (lineItem) => lineItem.trackById !== paymentId)
                  }
                });
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
      )
  }));
});

interface ProcessRefundProps {
  refundPayment: (input: ProcessRefundInput) => MutateResult<RefundPaymentMutation>;
  refundingPayment: boolean;
};

const processRefundEnhancer = wrapComponent<Props, ProcessRefundProps>((props) => {
  const { loading, mutate } = useRefundPaymentMutation();

  return computed(() => ({
    refundingPayment: loading.value,
    refundPayment: (input) =>
      mutate(
        { input },
        {
          async update(updateClient, { data }) {
            if (!data || !data?.refundPayment) {
              alert('An unexpected error occurred while processing the refund.');
              return;
            }

            const { success, message, refund } = data.refundPayment;
            alert(message || 'An unexpected error occurred.');

            if (success && refund) {
              try {
                const { client } = useApolloClient();

                const result = await client.query<GetRegistrationQuery>({
                  query: GetRegistrationDocument,
                  variables: { registrationID: props.registration.registrationID },
                  fetchPolicy: 'network-only'
                });

                if (result?.data) {
                  updateClient.writeFragment<SelectedRegistrationFragment>({
                    fragment: SelectedRegistrationFragmentDoc,
                    id: `${props.registration.registrationID}Registration`,
                    fragmentName: 'SelectedRegistration',
                    data: result?.data.registration
                  });
                }
              } catch (e) {
                console.log(e);
              }
            }
          }
        }
      )
  }));
});

export default compose(deletePaymentEnhancer, processRefundEnhancer)(Financials);
