import { ApolloClient } from '@apollo/client';
import {
  BulkNotifyParentsMutationVariables,
  CancelRegistrationMutationVariables,
  CompleteRegistrationMutationVariables,
  EventWithRegistrationsQuery,
  GetRegistrationQuery,
  MoveRegistrationToWaitListMutationVariables,
  NotifyParentsMutationVariables,
  ProduceInvoiceMutationVariables,
  PromoteRegistrationMutationVariables,
  RegistarAccountType,
  RegistrationListItemFragment,
  RegistrationPaymentStatus,
  ResendRegistrationEmailsMutationVariables,
  ResendWaiverEmailsMutationVariables,
  SecondaryInstitutionType,
  StatusType
} from 'shared/generated/graphql-types';
import { Me } from 'shared/types';

type Event = EventWithRegistrationsQuery['event'];
type Person = RegistrationListItemFragment['Teen']['Person'];
export type Registration = GetRegistrationQuery['registration'];

export enum WaiverStatus {
  SignedAll = 'SignedAll',
  SignedNone = 'SignedNone'
}

export enum BusStatus {
  AssignedOneWay = 'AssignedOneWay',
  AssignedTwoWay = 'AssignedTwoWay',
  Unassigned = 'Unassigned'
}

export enum ScholarshipStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Denied = 'Denied'
}
export interface Filters {
  paymentStatus: RegistrationPaymentStatus | 'NoPaymentsMade' | null;
  registrationStatus: StatusType | null;
  chapter: number | null;
  graduationYear: number | number[] | null;
  school: number | null;
  schoolType: SecondaryInstitutionType | null;
  ticketType: number | null;
  waiverStatus: WaiverStatus | null;
  busStatus: BusStatus | null;
  gender: Person['gender'] | null;
  scholarship: ScholarshipStatus | null;
  mediaConsent: number | null;
  dataConsent: number | null;
  liabilityConsent: number | null;
  eventWaiver: number | null;
  registarAccountType: RegistarAccountType | null;
  parentalApprovalGiven: number | null;
}

export interface Props {
  user: Me;
  eventId: number;
  event?: Event;
  registration?: Registration;
  client?: ApolloClient<any>;
  sendingConsentEmails: boolean;
  movingRegistrationToWaitingList: boolean;
  promotingRegistration: boolean;
  cancelingRegistration: boolean;
  completingRegistration: boolean;
  sendingRegistrationEmails: boolean;
  sendingWaiverEmails: boolean;
  producingInvoice: boolean;
  completeRegistration: (args: CompleteRegistrationMutationVariables) => void;
  cancelRegistration: (args: CancelRegistrationMutationVariables) => void;
  moveToWaitList: (args: MoveRegistrationToWaitListMutationVariables) => void;
  promoteRegistration: (args: PromoteRegistrationMutationVariables) => void;
  resendRegistrationEmails: (args: ResendRegistrationEmailsMutationVariables) => void;
  resendWaiverEmails: (args: ResendWaiverEmailsMutationVariables) => void;
  notifyParents: (args: NotifyParentsMutationVariables) => void;
  bulkNotifyParents: (args: BulkNotifyParentsMutationVariables) => void;
  produceInvoice: (args: ProduceInvoiceMutationVariables) => Promise<string | null>;
}
