import { CreateAddress, CreateEmail, CreatePhone, GetCommunityMemberProfileQuery, ResetPasswordMutationVariables, UpdateAddress, UpdateEmail, UpdatePhone } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

export type CommunityMember = GetCommunityMemberProfileQuery['singleCommunityMember'];
export type Address = ArrayElement<CommunityMember['Addresses']>;
export type Phone = ArrayElement<CommunityMember['Phones']>;
export type EmailAddress = ArrayElement<CommunityMember['EmailAddresses']>;

export interface Props {
  changePassword: (args: ResetPasswordMutationVariables) => void;
  updateEmail: (args: UpdateEmail, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  createEmail: (args: CreateEmail, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  updatePhone: (args: UpdatePhone, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  createPhone: (args: CreatePhone, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  updateAddress: (args: UpdateAddress, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  createAddress: (args: CreateAddress, optimisticResponseData?: CommunityMemberUpdateArgs) => void;
  personID: number;
  changingPassword: boolean;
  showPasswordModal: boolean;
  communityMember: CommunityMemberViewModel;
  isOpenForDuplciate: boolean;
  returnToProfile: () => void;
}
export enum CommunityMemberFieldsEnum {
  email = "email",
  landline = "landline",
  cellphone = "cellphone",
  address = "address",
  firstName = "firstName",
  lastName = "lastName",
  fullName = "fullName",
  gender = "gender",
  olamiId = "olamiId"
}
export interface CommunityMemberUpdateArgs {
  personId: number;
  fieldName: CommunityMemberFieldsEnum;
  value: string | null;
}
export interface CommunityMemberViewModel {
  address: Address | null;
  personId: number;
  landline: Phone | null;
  cellphone: Phone | null;
  formattedCellphone: string | null;
  formattedLandline: string | null;
  formattedAddress: string;
  email: string | null;
  emails: EmailAddress[];
  emailId: number | null;
  firstName: string | null;
  lastName: string | null;
  olamiId: string | null;
  fullName: string;
  dataOptOut: boolean | null;
  gender: number;
  original: CommunityMember;
}
