
import { defineComponent, h, PropType, ref } from 'vue';
import { MutateResult } from '@vue/apollo-composable';
import {
  AddParentToFamilyInput,
  AddRegistrationMutation,
  ConnectParentToTeenMutation,
  ConnectParentToTeenMutationVariables,
  GetEventTicketsForAddRegistrationQuery,
  NotAvailableReason,
  RegisterTeenInput
} from 'shared/generated/graphql-types';
import { TeenViewModel } from 'shared/components/TeenProfile/types';
import { EntityUnionViewModel } from 'shared/components/EmailInput/shared/EntityUnionViewModel';
import AddRegistrationForm from './AddRegistrationForm.vue';
import { isValueless } from 'shared/util';

type EventTickets = GetEventTicketsForAddRegistrationQuery['event']['EventTickets'];

export default defineComponent({
  props: {
    registeringTeen: { type: Boolean, required: true },
    registerTeen: {
      type: Function as PropType<
        (input: RegisterTeenInput) => MutateResult<AddRegistrationMutation>
      >,
      required: true
    },
    eventId: { type: Number, required: true },
    eventStartDate: { type: String, required: true },
    regionId: { type: Number, required: true },
    eventTickets: { type: Array as PropType<EventTickets>, required: true },
    selectedTeenId: { type: Number as PropType<number | null>, default: null },
    selectedTeenLoading: { type: Boolean, required: true },
    selectedTeen: { type: Object as PropType<TeenViewModel | null>, default: null },
    addingParentToFamily: { type: Boolean, required: true },
    addParentToFamily: {
      type: Function as PropType<
        (args: AddParentToFamilyInput) => MutateResult<ConnectParentToTeenMutation>
      >,
      required: true
    },
    connectingParentToTeen: { type: Boolean, required: true },
    connectParentToTeen: {
      type: Function as PropType<
        (
          args: ConnectParentToTeenMutationVariables,
          optimisticResponse: EntityUnionViewModel
        ) => MutateResult<ConnectParentToTeenMutation>
      >,
      required: true
    },
    setParentId: { type: Function as PropType<(parentId: number) => void>, required: true },
    setCommunityMemberId: {
      type: Function as PropType<(communityMemberId: number) => void>,
      required: true
    },
    setTeenId: { type: Function as PropType<(teenId: number) => void>, required: true },
    closeSidePanel: { type: Function as PropType<() => void>, required: true }
  },
  setup(props, { listeners }) {
    const overLimit = ref(false);
    const errorRegistering = ref(false);

    return () =>
      h(AddRegistrationForm, {
        on: {
          ...listeners
        },
        props: {
          ...props,
          overLimit: overLimit.value,
          errorRegistering: errorRegistering.value,
          registeringTeen: props.registeringTeen,
          registerTeen: async (
            teenID: number,
            eventID: number,
            eventTicketID: number,
            SuppressEmail: boolean,
            force?: boolean
          ) => {
            overLimit.value = false;
            errorRegistering.value = false;

            const result = await props.registerTeen({
              teenID,
              eventID,
              eventTicketID,
              SuppressEmail,
              force
            });

            if (result?.data?.registerTeen!.notAvailableReason === NotAvailableReason.OverLimit) {
              overLimit.value = true;
            } else if (!isValueless(result?.data?.registerTeen!.notAvailableReason)) {
              errorRegistering.value = true;
            }

            return result;
          }
        }
      });
  }
});
